import React from 'react'
import styled from 'styled-components'
import BoardBox from './boardBox'

import racoont from '../../images/projects/racoont.png'
import threejswebsite from '../../images/projects/threejsproject.png'
import pastel from '../../images/projects/pastel.png'
import movieLab from '../../images/projects/filmbox.png'

const BoardWrapper = styled.div`
  width: 100%;
  background: #212121;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 15px;
  top: -50px;
  padding-top: 56.25%;
  transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(-1deg);
  border-radius: 4px;
`
const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: 10px solid #222;
  border-radius: 4px;
`

const Board = () => {
  return (
    <BoardWrapper className="board__container">
      <Grid className="board__grid">
        {/* <BoardBox bgwebm={hoppWebM} title="HOPP" url="https://hopp.pl/" /> */}
        <BoardBox
          bgwebm={threejswebsite}
          title="My Useful Links"
          url="https://jassemdev.vercel.app/"
        />
        <BoardBox
          bgwebm={pastel}
          title="Grammarly Dark"
          url="https://chrome.google.com/webstore/detail/grammarly-dark/noojmhngiheennhgaffkfboanjpbkpep"
        />
        <BoardBox
          bgwebm={racoont}
          title="Racoont"
          url="https://racoont.herokuapp.com/"
        />
        <BoardBox
          bgwebm={movieLab}
          title="Movie Lab"
          url="https://jassem-lab.github.io/movies-lib/discover/Popular"
        />
      </Grid>
    </BoardWrapper>
  )
}

export default Board
