import React from 'react'
import '../../components/style/button.css'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Button = ({ link, content, ToLink }) => {
  return (
    <>
      <a href={link || ToLink } className="button__shape">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {content}
      </a>
    </>
  )
}
export default Button
