import React from 'react'
import styled from 'styled-components'
import Project from './project'

import racoont from '../images/projects/racoont.png'
import threejswebsite from '../images/projects/threejsproject.png'
import pastel from '../images/projects/pastel.png'
import oldportfolio from '../images/projects/oldportfolio.png'
import waves from '../images/projects/waves.png'
import movieBox from '../images/projects/filmbox.png'
import skybox from '../images/projects/skybox.png'

const ProjectsWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #111;
  padding: 100px 0;
`

const WorkWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px 20px;
  width: 100%;
`
const Title = styled.h2``
const Description = styled.p`
  text-align: center;
  margin: 50px 0;
  color: #bdbdbd;
`
const Animation = styled.div`
  &:nth-of-type(2),
  &:nth-of-type(5),
  &:nth-of-type(8) {
    margin-top: 50px;
  }

  &:nth-of-type(3),
  &:nth-of-type(6),
  &:nth-of-type(9) {
    margin-top: 100px;
  }

  &:hover {
    transform: translateY(-10px);
    transition: 0.4s;
    opacity: 1;
  }
`

export default function Projects() {
  return (
    <ProjectsWrapper id="projects">
      <div className="container container--secondary">
        <div className="container container--primary">
          <Title>My projects</Title>
          <Description className="work__desc section__desc">
            Check out my portfolio!
          </Description>
          <WorkWrapper className="work__wrapper">

            <Animation className="work--animation">
              <Project
                title="Useful Links 3D landing page"
                bgwebm={threejswebsite}
                github="https://github.com/jassem-lab"
                external="https://jassemdev.vercel.app/"
              />
            </Animation>
            <Animation className="work--animation">
              <Project
                title="PastelPack"
                bgwebm={pastel}
                github="https://github.com/jassem-lab"
                external="http://www.pastelpack.com/"
              />
            </Animation>
            <Animation className="work--animation">
              <Project
                title="Social media Network"
                bgwebm={racoont}
                github="https://github.com/jassem-lab"
                external="https://racoont.herokuapp.com/"
              />
            </Animation>
            <Animation className="work--animation">
              <Project
                title="Old Portfolio MERN App"
                bgwebm={oldportfolio}
                github="https://github.com/jassem-lab"
                external="https://jassemdev.herokuapp.com/"
              />
            </Animation>
            <Animation className="work--animation">
              <Project
                title="Three Js app"
                bgwebm={waves}
                github="https://github.com/jassem-lab/threejs_landingpage_waves"
                external="https://threejs-landingpage-waves-git-master-jassem-lab.vercel.app/"
              />
            </Animation>
            <Animation className="work--animation">
              <Project
                title="Movie library"
                bgwebm={movieBox}
                github="https://github.com/jassem-lab/movies-lib"
                external="https://jassem-lab.github.io/movies-lib/discover/Popular"
              />
            </Animation>
            <Animation className="work--animation">
              <Project
                title="Three js Skybox"
                bgwebm={skybox}
                github="https://github.com/jassem-lab/skybox_threejs"
                external="https://jg1.vercel.app/"
              />
            </Animation>
          </WorkWrapper>
        </div>
      </div>
    </ProjectsWrapper>
  )
}
